import localforage from 'localforage';
import Promise from 'bluebird';

const getItem = async (key) => localforage.getItem(key);
const clear = async () => localforage.clear();
const removeItem = async (key) => localforage.removeItem(key);
const saveTile = async (key, value) => {
  try {
    const item = await localforage.getItem(key);
    if (!item) {
      return await localforage.setItem(key, value);
    }
  } catch (err) {
    // This code runs if there were any errors.
    console.log(err);
  }
};

const setupSavedTiles = (setCounts) => {
  return async (tileUrls) => {
    const totalCount = tileUrls.length;
    await localforage.setItem('saved', 0);
    await localforage.setItem('totalCount', totalCount);
    setCounts({ savedCount: 0, totalCount });

    return Promise.mapSeries(tileUrls, async ({ key, url }, index) => {
      try {
        const response = await fetch(url);
        if (response.status === 200) {
          setCounts({ savedCount: index, totalCount });
          console.log('saved tile #', index, new Date());
          await localforage.setItem('saved', index);
          const blob = await response.blob();
          return saveTile(key, blob);
        }
      } catch {
        console.log('you dun goofed');
      }
    });
  };
};

const tileDb = (setCounts) => ({
  getItem,
  _saveTile: saveTile,
  _removeItem: removeItem,
  clear,
  saveTiles: setupSavedTiles(setCounts),
});

export default tileDb;
