import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import banner from '../assets/banner.png';

const SplashPage = ({ resetData, useShemyaIsland, state, updateField }) => {
  return (
    <div>
      {!window.navigator.onLine && (
        <Typography variant="h5" component="h5">
          You are offline
        </Typography>
      )}
      <br />
      <div className="flex justify-between">
        <Button
          className={'!bg-rose-500 mr-4'}
          variant="contained"
          color="secondary"
          onClick={() => {
            const okayToReset = window.confirm('Are you sure?');
            if (okayToReset) {
              resetData();
            }
          }}
        >
          Reset Survey Data
        </Button>
        <Button
          className={'!bg-blue-500'}
          variant="contained"
          color="primary"
          onClick={useShemyaIsland}
        >
          Start Survey
        </Button>
      </div>
      <Typography variant="h3" component="h1">
        EAS Wildlife Survey
      </Typography>
      <Typography variant="h5" component="h1">
        EWSLog
      </Typography>
      <div className={'h-[300px] flex justify-center'}>
        <img alt={'banner'} src={banner} />
      </div>
      <div className="flex justify-between">
        <TextField
          label={'Survey Date'}
          name={'surveyDate'}
          className="!mr-4 w-full"
          value={state.surveyDate}
          onChange={updateField}
          inputProps={{ style: { textAlign: 'center' } }}
        />
        <FormControl className={'w-full'}>
          <InputLabel shrink>Survey Type</InputLabel>
          <Select
            name={'surveyType'}
            value={state.surveyType}
            onChange={updateField}
          >
            <MenuItem value={'ERBW'}>ERBW</MenuItem>
            <MenuItem value={'INW'}>INW</MenuItem>
            <MenuItem value={'CBC'}>CBC</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="flex justify-between">
        <TextField
          label={'Start Time'}
          name={'startTime'}
          className="!mr-4 w-full"
          value={state.startTime}
          onChange={updateField}
          inputProps={{ style: { textAlign: 'center' } }}
        />
        <TextField
          type={'number'}
          label={'Visibility (miles)'}
          name={'visibility'}
          className="w-full"
          value={state.visibility}
          onChange={updateField}
          inputProps={{ style: { textAlign: 'center' } }}
        />
      </div>
      <div className="flex justify-between">
        <TextField
          type={'number'}
          label={'Wind Speed (mph)'}
          name={'windSpeed'}
          className="!mr-4 w-full"
          value={state.windSpeed}
          onChange={updateField}
          inputProps={{ style: { textAlign: 'center' } }}
        />
        <FormControl className="w-full">
          <InputLabel shrink>Window Direction</InputLabel>
          <Select
            name={'windDirection'}
            value={state.windDirection}
            onChange={updateField}
          >
            <MenuItem value={'N'}>N</MenuItem>
            <MenuItem value={'NE'}>NE</MenuItem>
            <MenuItem value={'E'}>E</MenuItem>
            <MenuItem value={'SE'}>SE</MenuItem>
            <MenuItem value={'S'}>S</MenuItem>
            <MenuItem value={'SW'}>SW</MenuItem>
            <MenuItem value={'W'}>W</MenuItem>
            <MenuItem value={'NW'}>NW</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default SplashPage;
