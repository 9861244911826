export const stringPezKml = `<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
<Document>
\t<name>Primary Exclusion Zone.kml</name>
\t<Style id="sh_ylw-pushpin">
\t\t<IconStyle>
\t\t\t<scale>1.3</scale>
\t\t\t<Icon>
\t\t\t\t<href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
\t\t\t</Icon>
\t\t\t<hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>
\t\t</IconStyle>
\t\t<BalloonStyle>
\t\t</BalloonStyle>
\t\t<LineStyle>
\t\t\t<color>ff0000ff</color>
\t\t\t<width>3</width>
\t\t</LineStyle>
\t\t<PolyStyle>
\t\t\t<fill>0</fill>
\t\t</PolyStyle>
\t</Style>
\t<StyleMap id="msn_ylw-pushpin">
\t\t<Pair>
\t\t\t<key>normal</key>
\t\t\t<styleUrl>#sn_ylw-pushpin</styleUrl>
\t\t</Pair>
\t\t<Pair>
\t\t\t<key>highlight</key>
\t\t\t<styleUrl>#sh_ylw-pushpin</styleUrl>
\t\t</Pair>
\t</StyleMap>
\t<Style id="sn_ylw-pushpin">
\t\t<IconStyle>
\t\t\t<scale>1.1</scale>
\t\t\t<Icon>
\t\t\t\t<href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
\t\t\t</Icon>
\t\t\t<hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>
\t\t</IconStyle>
\t\t<BalloonStyle>
\t\t</BalloonStyle>
\t\t<LineStyle>
\t\t\t<color>ff0000ff</color>
\t\t\t<width>3</width>
\t\t</LineStyle>
\t\t<PolyStyle>
\t\t\t<fill>0</fill>
\t\t</PolyStyle>
\t</Style>
\t<Placemark>
\t\t<name>Primary Exclusion Zone</name>
\t\t<styleUrl>#msn_ylw-pushpin</styleUrl>
\t\t<Polygon>
\t\t\t<tessellate>1</tessellate>
\t\t\t<outerBoundaryIs>
\t\t\t\t<LinearRing>
\t\t\t\t\t<coordinates>
\t\t\t\t\t\t174.0926184863502,52.7191877551867,0 174.0895423631445,52.71405244048679,0 174.1226938970971,52.70757042761699,0 174.1360728361735,52.70682887050767,0 174.1379875965816,52.71037365519373,0 174.0926184863502,52.7191877551867,0 
\t\t\t\t\t</coordinates>
\t\t\t\t</LinearRing>
\t\t\t</outerBoundaryIs>
\t\t</Polygon>
\t</Placemark>
</Document>
</kml>
`;
export const stringRezKml = `<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
<Document>
\t<name>Raven Exclusion Zone.kml</name>
\t<StyleMap id="msn_ylw-pushpin">
\t\t<Pair>
\t\t\t<key>normal</key>
\t\t\t<styleUrl>#sn_ylw-pushpin</styleUrl>
\t\t</Pair>
\t\t<Pair>
\t\t\t<key>highlight</key>
\t\t\t<styleUrl>#sh_ylw-pushpin</styleUrl>
\t\t</Pair>
\t</StyleMap>
\t<Style id="sh_ylw-pushpin">
\t\t<IconStyle>
\t\t\t<scale>1.3</scale>
\t\t\t<Icon>
\t\t\t\t<href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
\t\t\t</Icon>
\t\t\t<hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>
\t\t</IconStyle>
\t\t<BalloonStyle>
\t\t</BalloonStyle>
\t\t<LineStyle>
\t\t\t<color>ff00aaff</color>
\t\t\t<width>3</width>
\t\t</LineStyle>
\t\t<PolyStyle>
\t\t\t<fill>0</fill>
\t\t</PolyStyle>
\t</Style>
\t<Style id="sn_ylw-pushpin">
\t\t<IconStyle>
\t\t\t<scale>1.1</scale>
\t\t\t<Icon>
\t\t\t\t<href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
\t\t\t</Icon>
\t\t\t<hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>
\t\t</IconStyle>
\t\t<BalloonStyle>
\t\t</BalloonStyle>
\t\t<LineStyle>
\t\t\t<color>ff00aaff</color>
\t\t\t<width>3</width>
\t\t</LineStyle>
\t\t<PolyStyle>
\t\t\t<fill>0</fill>
\t\t</PolyStyle>
\t</Style>
\t<Placemark>
\t\t<name>Raven Exclusion Zone</name>
\t\t<styleUrl>#msn_ylw-pushpin</styleUrl>
\t\t<Polygon>
\t\t\t<tessellate>1</tessellate>
\t\t\t<outerBoundaryIs>
\t\t\t\t<LinearRing>
\t\t\t\t\t<coordinates>
\t\t\t\t\t\t174.0895417519165,52.7140531346179,0 174.0942507565762,52.71149583003648,0 174.0964747341773,52.71084283168121,0 174.0973774486219,52.7102698101907,0 174.0984618354791,52.71000230276888,0 174.1016693235338,52.70867246762861,0 174.1044198086749,52.7080459523877,0 174.1067727218729,52.70863688279537,0 174.1095194516437,52.70856604778015,0 174.1128824202617,52.70780210045609,0 174.1226897432832,52.70757142982523,0 174.0895417519165,52.7140531346179,0 
\t\t\t\t\t</coordinates>
\t\t\t\t</LinearRing>
\t\t\t</outerBoundaryIs>
\t\t</Polygon>
\t</Placemark>
</Document>
</kml>
`;
export const stringSezKml = `<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
<Document>
\t<name>SEZ.kml</name>
\t<StyleMap id="msn_ylw-pushpin">
\t\t<Pair>
\t\t\t<key>normal</key>
\t\t\t<styleUrl>#sn_ylw-pushpin</styleUrl>
\t\t</Pair>
\t\t<Pair>
\t\t\t<key>highlight</key>
\t\t\t<styleUrl>#sh_ylw-pushpin</styleUrl>
\t\t</Pair>
\t</StyleMap>
\t<Style id="sh_ylw-pushpin">
\t\t<IconStyle>
\t\t\t<scale>1.3</scale>
\t\t\t<Icon>
\t\t\t\t<href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
\t\t\t</Icon>
\t\t\t<hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>
\t\t</IconStyle>
\t\t<BalloonStyle>
\t\t</BalloonStyle>
\t\t<LineStyle>
\t\t\t<color>ff00aaff</color>
\t\t\t<width>3</width>
\t\t</LineStyle>
\t\t<PolyStyle>
\t\t\t<fill>0</fill>
\t\t</PolyStyle>
\t</Style>
\t<Style id="sn_ylw-pushpin">
\t\t<IconStyle>
\t\t\t<scale>1.1</scale>
\t\t\t<Icon>
\t\t\t\t<href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
\t\t\t</Icon>
\t\t\t<hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>
\t\t</IconStyle>
\t\t<BalloonStyle>
\t\t</BalloonStyle>
\t\t<LineStyle>
\t\t\t<color>ff00aaff</color>
\t\t\t<width>3</width>
\t\t</LineStyle>
\t\t<PolyStyle>
\t\t\t<fill>0</fill>
\t\t</PolyStyle>
\t</Style>
\t<Placemark>
\t\t<name>Secondary Exclusion Zone</name>
\t\t<styleUrl>#msn_ylw-pushpin</styleUrl>
\t\t<Polygon>
\t\t\t<tessellate>1</tessellate>
\t\t\t<outerBoundaryIs>
\t\t\t\t<LinearRing>
\t\t\t\t\t<coordinates>
\t\t\t\t\t\t174.0624529068531,52.71677371247838,0 174.0895435201269,52.71405226080549,0 174.0926186833141,52.71918756100205,0 174.1379876805285,52.71037305094984,0 174.1360744207751,52.70683006393593,0 174.1395179360631,52.70642487921105,0 174.1469895186762,52.70649457848935,0 174.148225288979,52.70555736967907,0 174.1496928564541,52.70534554028328,0 174.1511104761047,52.7047883921224,0 174.1525223812885,52.70473581444394,0 174.1555410210177,52.70580107873685,0 174.1531046371359,52.70798896258945,0 174.1528338364667,52.70906137688812,0 174.1388779716462,52.71034225334051,0 174.1382665994745,52.71072399046289,0 174.1377030479857,52.71094945256999,0 174.1337789629231,52.71255389009671,0 174.1330882884733,52.71272407136366,0 174.132291817119,52.71281929206486,0 174.1314489190161,52.71279535372253,0 174.129792945626,52.71274284087049,0 174.1294591235472,52.71277028549534,0 174.1292984893694,52.71284616526857,0 174.1288717212119,52.71330416133912,0 174.1282129460466,52.71344929593777,0 174.1255145295943,52.71344418947308,0 174.1251016190104,52.7134934578099,0 174.1247375407307,52.71362407493141,0 174.1226365000452,52.7156123719441,0 174.1223502869001,52.71579546614809,0 174.1176453606105,52.71767826073386,0 174.1172149294099,52.71779476319559,0 174.1114300227068,52.71839369234025,0 174.1105644850806,52.71838904053445,0 174.1087967246873,52.71797408432133,0 174.1082651575973,52.71791612309806,0 174.1020286040674,52.71776572228114,0 174.0999705530758,52.7183684028675,0 174.0989783500338,52.71841231416615,0 174.0987732984073,52.71911042095043,0 174.0977545478973,52.71941998495405,0 174.0926232200971,52.71918861345953,0 174.0752812839277,52.72473676299155,0 174.0583222976386,52.71818586801535,0 174.0624529068531,52.71677371247838,0 
\t\t\t\t\t</coordinates>
\t\t\t\t</LinearRing>
\t\t\t</outerBoundaryIs>
\t\t</Polygon>
\t</Placemark>
</Document>
</kml>
`;
