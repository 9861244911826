import { useEffect } from 'react';
import { getCurrentPositionPromise } from 'geolocation-promise';

import setupMap from '../utils/setupMap';
import samPoints from '../data/samPoints.json';
import viewPoints from '../data/viewPoints.json';

export const MAP_ID = 'mapid';

const INNER_RING_DISTANCE_IN_METERS = 10;
const MIDDLE_RING_DISTANCE_IN_METERS = 50;
export const OUTER_RING_DISTANCE_IN_METERS = 100;

const SetupMapDesignatedPoints = (
  currentLatitude,
  currentLongitude,
  map,
  state,
  addBirdToMap,
  setMap,
  setCounts,
) => {
  useEffect(() => {
    if (currentLatitude && currentLongitude && !map) {
      let lMap = window.L.map(MAP_ID).setView(
        [currentLatitude, currentLongitude],
        13,
      );
      setupMap(lMap, setCounts);
      setMap(lMap);
      if (state.birdList?.length) {
        state.birdList.forEach((bird) => {
          addBirdToMap(lMap, bird.lat, bird.lng);
        });
      }
      if (samPoints.length) {
        const getOptions = (samPoint, options) => {
          let fillColor;
          switch (samPoint.group) {
            case 'A':
              fillColor = 'red';
              break;
            case 'B':
              fillColor = 'blue';
              break;
            case 'C':
              fillColor = 'orange';
              break;
            default:
              fillColor = 'purple';
              break;
          }

          return {
            fillColor,
            stroke: false,
            fillOpacity: 0.1,
            ...options,
          };
        };

        samPoints.forEach((samPoint) => {
          new window.L.Circle(
            [samPoint.lat, samPoint.lng],
            getOptions(samPoint, { radius: OUTER_RING_DISTANCE_IN_METERS }),
          ).addTo(lMap);
          new window.L.Circle(
            [samPoint.lat, samPoint.lng],
            getOptions(samPoint, { radius: MIDDLE_RING_DISTANCE_IN_METERS }),
          ).addTo(lMap);

          new window.L.Circle(
            [samPoint.lat, samPoint.lng],
            getOptions(samPoint, { radius: INNER_RING_DISTANCE_IN_METERS }),
          ).addTo(lMap);

          new window.L.Circle(
            [samPoint.lat, samPoint.lng],
            getOptions(samPoint, {
              radius: 1,
              color: 'black',
              stroke: true,
              opacity: 1,
            }),
          ).addTo(lMap);
        });
      }
      if (viewPoints.length) {
        viewPoints.forEach((viewPoint) => {
          new window.L.Circle([viewPoint.lat, viewPoint.lng], {
            radius: 10,
            color: 'lime',
          }).addTo(lMap);
        });
      }
      const addPositionMarker = async () => {
        const position = await getCurrentPositionPromise();
        new window.L.CircleMarker(
          [position.coords.latitude, position.coords.longitude],
          { radius: 1 },
        ).addTo(lMap);
      };
      addPositionMarker();
    }
  }, [
    map,
    state.birdList,
    currentLatitude,
    currentLongitude,
    addBirdToMap,
    setMap,
    setCounts,
  ]);
};

export default SetupMapDesignatedPoints;
