import React, { useEffect, useState } from 'react';
import 'leaflet';
import 'leaflet-offline';
import 'leaflet-kml';
import localforage from 'localforage';
import { ThemeProvider } from '@material-ui/styles';
import createTheme from '@material-ui/core/styles/createTheme';

import './App.css';
import uuid from '../utils/uuid';
import { getHourMinuteSeconds, getMonthDayYear } from '../utils/date';
import SplashPage from '../components/SplashPage';
import SurveyPage from '../components/SurveyPage';
import useLocalStorageBirds from '../utils/useLocalStorageBirds';
import BirdModal from '../components/BirdModal';
import useInterval from '../utils/useInterval';
import SetupOnMouseClick from '../utils/SetupOnMouseClick';
import SetupMapDesignatedPoints from '../utils/SetupMapDesignatedPoints';
import GetCurrentPositionHook from '../utils/GetCurrentPositionHook';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#858282',
    },
  },
});

const defaultState = {
  surveyDate: getMonthDayYear(),
  startTime: getHourMinuteSeconds(),
  visibility: '',
  windSpeed: '',
  windDirection: 'N',
  surveyType: 'ERBW',
  birdList: [],
  samSurveyData: undefined,
};

const addBirdToMap = (map, lat, lng) => {
  new window.L.Circle([lat, lng], {
    radius: 1,
    color: 'yellow',
  }).addTo(map);
};

const App = () => {
  const [samSurveyTimerString, setSamSurveyTimerString] = useState(false);
  const [currentUserLocation, setCurrentUserLocation] = useState(undefined);
  const [modalBird, setModalBird] = useState(undefined);
  const [counts, setCounts] = useState({ savedCount: 0, totalCount: 0 });
  const [map, setMap] = useState();
  const [state, setState] = useState(defaultState);
  const [currentLatitude, setCurrentLatitude] = useState();
  const [currentLongitude, setCurrentLongitude] = useState();
  const setLocation = (latitude, longitude) => {
    setCurrentLatitude(latitude);
    setCurrentLongitude(longitude);
  };
  const useShemyaIsland = () => setLocation(52.720664, 174.125858);
  const updateField = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const addBird = (bird, isNew, index) => {
    let birdList = [...state.birdList];

    if (isNew) {
      birdList = [bird, ...birdList];
      addBirdToMap(map, bird.lat, bird.lng);
    } else {
      birdList[index] = {
        ...birdList[index],
        ...bird,
      };
    }

    let newState = {
      ...state,
      birdList,
    };

    if (bird.samSurveyData) {
      newState = {
        ...newState,
        samSurveyData: bird.samSurveyData,
      };
    }

    if (bird.species.code === 'END') {
      newState = {
        ...newState,
        samSurveyData: undefined,
      };
    }

    setState(newState);
  };
  const updateBirdField = (index) => (e) => {
    const updatedBird = {
      ...state.birdList[index],
      [e.target.name]: e.target.value,
    };
    return addBird(updatedBird, false, index);
  };
  const removeBird = (index) => (e) => {
    let newArr = [...state.birdList];
    const removedBird = newArr.splice(index, 1)[0];
    const layerToRemove = Object.values(map._layers).find(
      (layer) =>
        layer._latlng &&
        layer._latlng.lat === removedBird.lat &&
        layer._latlng.lng === removedBird.lng,
    );
    if (layerToRemove) {
      map.removeLayer(layerToRemove);
    }
    setState({
      ...state,
      birdList: newArr,
    });
  };
  const resetData = () => {
    localforage.removeItem('weFlock', () => {
      setState(defaultState);
    });
  };
  useLocalStorageBirds(setState);
  SetupMapDesignatedPoints(
    currentLatitude,
    currentLongitude,
    map,
    state,
    addBirdToMap,
    setMap,
    setCounts,
  );
  SetupOnMouseClick(map, state, setState, setModalBird);
  useEffect(() => {
    localforage.setItem('weFlock', JSON.stringify(state));
  }, [state]);
  GetCurrentPositionHook(setCurrentUserLocation);
  useInterval(
    () => {
      if (state.samSurveyData) {
        const start = new Date(state.samSurveyData.date);
        const now = new Date();
        // Note: Add 10 Minutes as it's a 10 minute survey
        const endOfSurvey = new Date(start.getTime() + 10 * 60000);
        const minutes = String(
          parseInt(
            (Math.abs(start.getTime() - now.getTime()) / (1000 * 60)) % 60,
          ),
        ).padStart(2, '0');
        const seconds = String(
          parseInt((Math.abs(start.getTime() - now.getTime()) / 1000) % 60),
        ).padStart(2, '0');

        if (minutes < 10) {
          setSamSurveyTimerString(`${minutes}:${seconds}`);
        } else {
          // Note: Cleaning up all of our data to prepare for next survey
          addBird(
            {
              id: uuid(),
              species: { code: 'END', commonName: 'End SAM Survey' },
              surveyType: 'SAM',
              number: 1,
              notes: '',
              time: getHourMinuteSeconds(endOfSurvey),
              behavior: ['VISUAL'],
              samStart: state.samSurveyData.time,
              lat: state.samSurveyData.samPoint.lat,
              lng: state.samSurveyData.samPoint.lng,
              formattedLatitude: state.samSurveyData.samPoint.lat.toFixed(5),
              formattedLongitude: state.samSurveyData.samPoint.lng.toFixed(5),
              samPointId: state.samSurveyData.samPoint.id,
            },
            true,
          );
          setSamSurveyTimerString();
        }
      }
    },
    state.samSurveyData ? 1000 : null,
  );
  const sortedBirds = state.birdList.sort((a, b) => (a.date > b.date ? -1 : 1));

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {(!currentLatitude || !currentLongitude) && (
          <SplashPage
            resetData={resetData}
            useShemyaIsland={useShemyaIsland}
            state={state}
            updateField={updateField}
          />
        )}
        {currentLatitude && currentLongitude && (
          <SurveyPage
            state={state}
            counts={counts}
            currentUserLocation={currentUserLocation}
            samSurveyTimerString={samSurveyTimerString}
            sortedBirds={sortedBirds}
            updateBirdField={updateBirdField}
            removeBird={removeBird}
            map={map}
          />
        )}
        {modalBird && (
          <BirdModal
            bird={modalBird}
            samSurveyData={state.samSurveyData}
            handleClose={() => setModalBird(undefined)}
            handleOk={(bird) => addBird(bird, true)}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

export default App;
