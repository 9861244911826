import React, { memo } from 'react';
import { FixedSizeList as List, areEqual } from 'react-window';
import memoize from 'memoize-one';

import BirdForm from '../components/BirdForm';

const createItemData = memoize((birdList, updateBirdField, removeBird) => ({
  birdList,
  updateBirdField,
  removeBird,
}));

const Row = memo(
  ({ data: { birdList, updateBirdField, removeBird }, index, style }) => {
    const bird = birdList[index];

    return (
      <div style={style} key={bird.id}>
        <BirdForm
          bird={bird}
          updateBird={(props) => updateBirdField(index)(props)}
          deleteBird={removeBird(index)}
        />
      </div>
    );
  },
  areEqual,
);

const BirdList = ({ birdList, updateBirdField, removeBird }) => {
  const itemData = createItemData(birdList, updateBirdField, removeBird);

  return (
    <div className={'list'}>
      <List
        height={100}
        itemCount={itemData.birdList.length}
        itemData={itemData}
        itemSize={80}
        width={'100%'}
      >
        {Row}
      </List>
    </div>
  );
};

export default BirdList;
