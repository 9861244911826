import React from 'react';
import MuiModal from '@material-ui/core/Modal';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

import './DaySummary.css';

const width = 750;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    minHeight: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
}));

const DaySummaryModal = ({ state, handleClose }) => {
  const classes = useStyles();

  const accumulatedBirdList = state.birdList.reduce((acc, bird) => {
    const {
      species: { code, commonName },
      number,
    } = bird;

    acc[code] = acc[code]
      ? {
          commonName,
          individuals: Number(acc[code].individuals) + Number(number),
          detections: Number(acc[code].detections) + 1,
        }
      : {
          commonName,
          individuals: number,
          detections: 1,
        };

    return acc;
  }, {});

  return (
    <MuiModal open={true} onClose={() => false}>
      <div
        style={{
          left: `calc(50% - ${width / 2}px)`,
        }}
        className={classes.paper}
      >
        <table className="DaySummary w-full">
          <thead>
            <tr>
              <th>Count</th>
              <th>Species</th>
              <th>Total Individuals</th>
              <th>Total Detections</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(accumulatedBirdList).map((accBird, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{accBird.commonName}</td>
                  <td>{accBird.individuals}</td>
                  <td>{accBird.detections}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="m-2 flex items-center justify-center">
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </div>
    </MuiModal>
  );
};

export default DaySummaryModal;
