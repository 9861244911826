import { useEffect } from 'react';
import samPoints from '../data/samPoints.json';
import { arePointsWithinDistance } from '../utils/arePointsWithinDistance';
import uuid from '../utils/uuid';
import { getHourMinuteSeconds } from '../utils/date';
import { OUTER_RING_DISTANCE_IN_METERS } from '../utils/SetupMapDesignatedPoints';

const SetupOnMouseClick = (map, state, setState, setModalBird) => {
  useEffect(() => {
    const onClick = (e) => {
      const samPoint = samPoints.find((samPoint) =>
        arePointsWithinDistance(
          e.latlng,
          samPoint,
          OUTER_RING_DISTANCE_IN_METERS,
        ),
      );

      setModalBird({
        id: uuid(),
        species: { code: undefined, commonName: undefined },
        number: 1,
        notes: '',
        time: getHourMinuteSeconds(),
        behavior: ['VISUAL'],
        lat: e.latlng.lat,
        lng: e.latlng.lng,
        formattedLatitude: e.latlng.lat.toFixed(5),
        formattedLongitude: e.latlng.lng.toFixed(5),
        samPoint,
      });
    };

    if (map) {
      map.off('click');
      map.on('click', onClick);
    }
  }, [map, state, setState, setModalBird]);
};

export default SetupOnMouseClick;
