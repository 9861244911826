import { getHourMinuteSeconds } from '../utils/date';
import json2csv from 'json2csv';

const exportDataCsv = ({
  birdList,
  surveyDate,
  startTime,
  visibility,
  windSpeed,
  windDirection,
  surveyType,
  samSurveyData,
  ...restOfState
}) => {
  if (birdList?.length) {
    const exportBirds = birdList.map(
      ({
        id,
        species,
        number,
        time,
        behavior,
        lat,
        lng,
        formattedLatitude,
        formattedLongitude,
        notes,
        samPointId,
        samPoint,
        samSurveyData,
        surveyType: birdSurveyType,
        samStart,
        ...rest
      }) => ({
        'Survey Date': surveyDate,
        'Start Time': startTime,
        'End Time': getHourMinuteSeconds(),
        Visibility: visibility,
        'Wind Speed': windSpeed,
        'Wind Direction': windDirection,
        'Survey Type': birdSurveyType ?? surveyType,
        Point: samPointId,
        'SAM START': samStart,
        ...restOfState,
        'Species Code': species.code,
        'Species Common Name': species.commonName,
        Number: number,
        Time: time,
        'Behavior(s)': behavior.join(','),
        Latitude: formattedLatitude,
        Longitude: formattedLongitude,
        Notes: notes,
        ...rest,
      }),
    );
    const csvState = json2csv.parse(exportBirds);
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvState);
    hiddenElement.target = '_blank';
    hiddenElement.download = `EWS_${surveyType}_${surveyDate}.csv`;
    hiddenElement.click();
  }
};

export default exportDataCsv;
