// Taken From: https://stackoverflow.com/questions/24680247/check-if-a-latitude-and-longitude-is-within-a-circle-google-maps#
// For such short distances, and when the accuracy doesn't have to be exact to the centimeter,
// you can treat the surface of the earth as flat. Calculate a conversion from degrees to kilometers
// at the latitude of the center point, then the Pythagorean theorem can be used to get the distance

export const arePointsWithinDistance = (checkPoint, centerPoint, meters) => {
  const kilometers = meters / 1000;
  const ky = 40000 / 360;
  const kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
  const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
  const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;

  return Math.sqrt(dx * dx + dy * dy) <= kilometers;
};
