export const getMonthDayYear = (d = new Date()) => {
  return [
    d.getMonth() + 1,
    d.getDate(),
    d.getFullYear().toString().substr(-2),
  ].join('/');
};

export const getHourMinutes = (d = new Date()) => {
  return [d.getHours(), String(d.getMinutes()).padStart(2, '0')].join(':');
};

export const getHourMinuteSeconds = (d = new Date()) => {
  return [
    d.getHours(),
    String(d.getMinutes()).padStart(2, '0'),
    String(d.getSeconds()).padStart(2, '0'),
  ].join(':');
};
