import { useEffect } from 'react';
import localforage from 'localforage';

// Note: loads birds into state from localstorage
const useLocalStorageBirds = (setState) => {
  useEffect(() => {
    const fetchData = async () => {
      const weFlock = await localforage.getItem('weFlock');

      if (weFlock && weFlock.length) {
        setState(JSON.parse(weFlock));
      }
    };
    fetchData();
  }, [setState]);
};

export default useLocalStorageBirds;
