import React from 'react';
import ReactDOMServer from 'react-dom/server';
import 'leaflet';
import 'leaflet-offline';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import tileDb from './tileDb';

const MIN_ZOOM = 14;
const MAX_ZOOM = 17;

const setupMap = async (map, setCounts) => {
  const mapLink = '<a href="http://www.esri.com/">Esri</a>';
  const whoLink =
    'i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';
  const setupDb = tileDb(setCounts);
  const offlineLayer = window.L.tileLayer.offline(
    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    setupDb,
    {
      attribution: '&copy; ' + mapLink + ', ' + whoLink,
      subdomains: 'abc',
      minZoom: MIN_ZOOM,
      maxZoom: MAX_ZOOM,
      crossOrigin: true,
    },
  );
  offlineLayer.addTo(map);

  if (window.navigator.onLine) {
    const offlineControl = window.L.control.offline(offlineLayer, setupDb, {
      saveButtonHtml: ReactDOMServer.renderToString(<SaveIcon />),
      removeButtonHtml: ReactDOMServer.renderToString(<DeleteIcon />),
      confirmSavingCallback: (nTilesToSave, continueSaveTiles) => {
        if (window.confirm(`Save ${nTilesToSave} tiles?`)) {
          continueSaveTiles();
        }
      },
      confirmRemovalCallback: function (continueRemoveTiles) {
        if (window.confirm('Remove all the tiles?')) {
          continueRemoveTiles();
        }
      },
      minZoom: MIN_ZOOM,
      maxZoom: MAX_ZOOM,
    });
    offlineControl.addTo(map);
  }

  offlineLayer.on('offline:below-min-zoom-error', () => {
    alert('Can not save tiles below minimum zoom level.');
  });

  offlineLayer.on('offline:save-start', (data) => {
    console.log('Saving ' + data.nTilesToSave + ' tiles.');
  });

  offlineLayer.on('offline:save-end', () => {
    alert('All the tiles were saved.');
  });

  offlineLayer.on('offline:save-error', (err) => {
    console.error('Error when saving tiles: ' + err);
  });

  offlineLayer.on('offline:remove-start', () => {
    console.log('Removing tiles.');
  });

  offlineLayer.on('offline:remove-end', () => {
    alert('All the tiles were removed.');
  });

  offlineLayer.on('offline:remove-error', (err) => {
    console.error('Error when removing tiles: ' + err);
  });
};

export default setupMap;
