import { useEffect } from 'react';

import { getCurrentPositionPromise } from 'geolocation-promise';

const GetCurrentPositionHook = (setCurrentUserLocation) => {
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const position = await getCurrentPositionPromise();

        setCurrentUserLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      } catch (e) {
        console.log(e);
      }
    };

    return fetchLocation();
  }, [setCurrentUserLocation]);
};

export default GetCurrentPositionHook;
