import React, { useState } from 'react';
import MuiModal from '@material-ui/core/Modal';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

import BirdForm from './BirdForm';
import { getHourMinuteSeconds } from '../utils/date';
import quickLinkSpecies from '../data/quickLinkSpecies.json';

const width = 700;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'auto',
  },
}));

const quickLinkColors = [
  '!bg-blue-500',
  '!bg-stone-500',
  '!bg-red-500',
  '!bg-amber-500',
  '!bg-emerald-500',
  '!bg-violet-500',
  '!bg-fuschia-500',
  '!bg-rose-500',
  '!bg-indigo-500',
  '!bg-yellow-500',
];

const BirdModal = ({ bird, handleClose, handleOk, samSurveyData }) => {
  const classes = useStyles();
  const [inProgressBird, setInProgressBird] = useState(bird);
  const [isValid, setIsValid] = useState(false);
  const updateBird = (e) => {
    const newBird = { ...inProgressBird, [e.target.name]: e.target.value };
    setInProgressBird(newBird);

    let valid = true;

    if (newBird.behavior.length === 0) {
      valid = false;
    }

    if (!newBird.species) {
      valid = false;
    } else if (newBird.species.length === 0) {
      valid = false;
    }

    setIsValid(valid);
  };
  const handleAdd = () => {
    handleClose();
    let birdToAdd = {
      ...inProgressBird,
    };

    if (samSurveyData) {
      birdToAdd = {
        ...birdToAdd,
        surveyType: 'SAM',
        samStart: samSurveyData.time,
        samPointId: samSurveyData.samPoint.id,
      };
    }

    return handleOk(birdToAdd);
  };
  const onClickStartSamSurvey = () => {
    handleClose();
    const { samPoint, ...restOfBird } = inProgressBird;
    const date = new Date();
    const samSurveyData = {
      samPoint,
      date,
      time: getHourMinuteSeconds(date),
    };

    // Note: Need to start the time exactly when the survey button is clicked
    return handleOk({
      ...restOfBird,
      species: { code: 'START', commonName: 'Start SAM Survey' },
      surveyType: 'SAM',
      samStart: samSurveyData.time,
      time: samSurveyData.time,
      samPointId: samSurveyData.samPoint.id,
      lat: samSurveyData.samPoint.lat,
      lng: samSurveyData.samPoint.lng,
      formattedLatitude: samSurveyData.samPoint.lat.toFixed(5),
      formattedLongitude: samSurveyData.samPoint.lng.toFixed(5),
      samSurveyData,
    });
  };

  return (
    <MuiModal open={true} onClose={() => false}>
      <div
        style={{
          left: `calc(40% - ${width / 2}px)`,
        }}
        className={classes.paper}
      >
        <div className="flex justify-between mb-4">
          <div className="w-3/5 mr-3">
            <div className="flex justify-between items-center mb-4">
              <h2>New</h2>
              {bird.samPoint && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onClickStartSamSurvey}
                >
                  Start SAM Survey
                </Button>
              )}
            </div>
            <BirdForm
              bird={inProgressBird}
              updateBird={updateBird}
              className={'bird-vertical'}
            />
          </div>
          <div className="flex flex-col justify-between w-2/5">
            {quickLinkSpecies.map((bird, index) => (
              <Button
                className={`${quickLinkColors[index % quickLinkColors.length]}`}
                onClick={() => {
                  updateBird({
                    target: {
                      name: 'species',
                      value: {
                        ...bird,
                      },
                    },
                  });
                }}
              >
                {bird.code}
              </Button>
            ))}
          </div>
        </div>
        <div className={'w-full flex justify-between'}>
          <Button
            className={'w-full !mr-4 !bg-rose-500'}
            variant="contained"
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={'w-full disabled:!bg-stone-500 !bg-blue-500'}
            variant="contained"
            color="primary"
            disabled={!isValid}
            onClick={handleAdd}
          >
            Add
          </Button>
        </div>
      </div>
    </MuiModal>
  );
};

export default BirdModal;
