import React, { Fragment } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import species from '../data/species.json';

const birdForm = ({ className, bird, updateBird, deleteBird }) => (
  <form
    autoComplete={'off'}
    key={bird.id}
    className={`bird ${className ?? ''}`}
  >
    <Autocomplete
      name={'species'}
      options={species}
      value={bird.species}
      onChange={(e, newValue) => {
        updateBird({
          ...e,
          target: {
            ...e.target,
            name: 'species',
            value: newValue,
          },
        });
      }}
      renderOption={(option) => {
        if (option.code && option.commonName) {
          return (
            <Fragment>
              {option.code} - {option.commonName}
            </Fragment>
          );
        }
        return '';
      }}
      getOptionSelected={(option, value) => option.code === value.code}
      getOptionDisabled={(option) => !option.code || !option.commonName}
      getOptionLabel={(option) => option.code}
      style={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Species"
          {...(deleteBird ? {} : { autoFocus: true })}
        />
      )}
    />
    <TextField
      type={'number'}
      label={'Number'}
      name={'number'}
      InputProps={{
        inputProps: {
          min: 1,
          className: 'bird-count-class',
        },
      }}
      value={bird.number}
      onChange={updateBird}
      style={{ width: '75px' }}
    />
    <FormControl style={{ width: '175px' }}>
      <InputLabel shrink>Behavior(s)</InputLabel>
      <Select
        name={'behavior'}
        multiple
        value={bird.behavior}
        onChange={updateBird}
        error={bird.behavior.length === 0}
      >
        <MenuItem value={'VISUAL'}>VISUAL</MenuItem>
        <MenuItem value={'SINGING'}>SINGING</MenuItem>
        <MenuItem value={'CALLING'}>CALLING</MenuItem>
        <MenuItem value={'FLYOVER'}>FLYOVER</MenuItem>
        <MenuItem value={'MALE'}>MALE</MenuItem>
        <MenuItem value={'FEMALE'}>FEMALE</MenuItem>
        <MenuItem value={'NEST'}>NEST</MenuItem>
      </Select>
    </FormControl>
    <TextField
      label={'Notes'}
      name={'notes'}
      value={bird.notes}
      onChange={updateBird}
    />
    <TextField
      label={'Time'}
      name={'time'}
      value={bird.time}
      disabled
      style={{ width: '57px' }}
    />
    <TextField
      label={'lat'}
      name={'lat'}
      value={bird.formattedLatitude}
      disabled
      style={{ width: '170px' }}
    />
    <TextField
      label={'lng'}
      name={'lng'}
      value={bird.formattedLongitude}
      disabled
      style={{ width: '170px' }}
    />
    {deleteBird && (
      <Button
        className="!bg-rose-500"
        variant={'contained'}
        color={'secondary'}
        onClick={deleteBird}
      >
        <DeleteIcon />
      </Button>
    )}
  </form>
);

export default birdForm;
