import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import LayersIcon from '@material-ui/icons/Layers';

import BirdList from '../components/BirdList';
import exportDataCsv from '../utils/exportDataCsv';
import { MAP_ID } from '../utils/SetupMapDesignatedPoints';
import DaySummaryModal from '../components/DaySummaryModal';
import { stringPezKml, stringRezKml, stringSezKml } from '../data/kmlConstants';

const SurveyPage = ({
  state,
  counts,
  currentUserLocation,
  samSurveyTimerString,
  sortedBirds,
  updateBirdField,
  removeBird,
  map,
}) => {
  const [showDaySummary, setShowDaySummary] = useState(false);
  const [isKmlShowing, setIsKmlShowing] = useState(false);
  const toggleKmls = () => {
    if (!isKmlShowing) {
      const parser = new DOMParser();

      const pezKml = parser.parseFromString(stringPezKml, 'text/xml');
      const rezKml = parser.parseFromString(stringRezKml, 'text/xml');
      const sezKml = parser.parseFromString(stringSezKml, 'text/xml');

      const pezTrack = new window.L.KML(pezKml);
      const rezTrack = new window.L.KML(rezKml);
      const sezTrack = new window.L.KML(sezKml);

      map.addLayer(pezTrack);
      map.addLayer(rezTrack);
      map.addLayer(sezTrack);
    } else {
      if (map && map._layers) {
        const layersToRemove = Object.values(map._layers).filter(
          (layer) => layer._kml,
        );
        layersToRemove.forEach((layerToRemove) => {
          map.removeLayer(layerToRemove);
        });
      }
    }

    setIsKmlShowing(!isKmlShowing);
  };

  return (
    <>
      <div id={MAP_ID} />
      {counts.totalCount > 0 && (
        <div>
          total saved - {counts.savedCount} / {counts.totalCount}
        </div>
      )}
      <div className="flex flex-wrap max-h-[75px] justify-between">
        <Button
          className="!mr-4 !mb-2 disabled:!bg-stone-500 !bg-emerald-500"
          variant={'contained'}
          color={'primary'}
          onClick={() => setShowDaySummary(true)}
          disabled={!state.birdList?.length}
        >
          Day Summary
        </Button>
        <Button
          className="!mr-4 !mb-2 !bg-rose-500"
          variant={'contained'}
          color={'secondary'}
          onClick={() => {
            if (currentUserLocation) {
              map.setView([
                currentUserLocation.latitude,
                currentUserLocation.longitude,
              ]);
            }
          }}
        >
          Scroll to Current Location
        </Button>
        <Button
          className="!mr-4 !mb-2 disabled:!bg-stone-500 !bg-blue-500"
          variant={'contained'}
          color={'primary'}
          onClick={() => exportDataCsv(state)}
          disabled={!state.birdList?.length}
        >
          End Survey / Export Data
        </Button>
        <span className={'!mb-2'}>
          {state.samSurveyData
            ? samSurveyTimerString
            : 'Not Within Sam Survey Time'}
        </span>
        <Button
          className="!mr-4 !mb-2 disabled:!bg-stone-500 !bg-red-500"
          variant={'contained'}
          color={'primary'}
          onClick={toggleKmls}
        >
          {isKmlShowing ? 'Hide' : 'Show'} <LayersIcon />
        </Button>
      </div>
      <BirdList
        birdList={sortedBirds}
        updateBirdField={updateBirdField}
        removeBird={removeBird}
      />
      {showDaySummary && (
        <DaySummaryModal
          state={state}
          handleClose={() => setShowDaySummary(false)}
        />
      )}
    </>
  );
};

export default SurveyPage;
